<template>
  <div>
    <Navbar />
    <!-- ! Main Begin -->
    <main id="main" class="main">
      <section class="home-banner-section inner-banner cover-bg" id="banner">
        <div class="banner-caption">
          <div class="banner-caption-content text-white">
            <div
              class="
                banner-caption-text
                d-flex
                w-100
                align-items-center
                flex-warp
                h-100
                py-5
              "
            >
              <div class="container">
                <div class="banner-text">
                  <h1 class="title-border">Disclaimer</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="curved-section-main">
        <!-- ! Section start -->
        <section class="section curved-section no-min-height">
          <div class="section-shape section-shape-top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1920"
              height="84.752"
              viewBox="0 0 1920 84.752"
              class="shape-1"
            >
              <g transform="translate(0 -0.248)">
                <g transform="translate(0 -402)">
                  <path
                    d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="container section-container-padding">
            <h2>Disclaimer</h2>
            <p class="text-secondary">Last updated October 20, 2021</p>
            <h3>Website disclaimer</h3>
            <p>
              The information provided by JAB Companies, Inc. (“we,” “us”, or
              “our”) on
              <a href="http://www.vaxtraxglobal.com">www.vaxtraxglobal.com</a>
              (the “Site”) and our mobile application is for general
              informational purposes only. All information on the Site and our
              mobile application is provided in good faith, however we make no
              representation or warranty of any kind, express or implied,
              regarding the accuracy, adequacy, validity, reliability,
              availability or completeness of any information on the Site or our
              mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
              LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
              RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR
              RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE
              APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND
              YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE
              APPLICATION IS SOLELY AT YOUR OWN RISK.
            </p>
            <h3>Testimonials disclaimer</h3>
            <p>
              The Site may contain testimonials by users of our products and/or
              services. These testimonials reflect the real-life experiences and
              opinions of such users. However, the experiences are personal to
              those particular users, and may not necessarily be representative
              of all users of our products and/or services. We do not claim, and
              you should not assume, that all users will have the same
              experiences. YOUR INDIVIDUAL RESULTS MAY VARY.
            </p>
            <p>
              The testimonials on the Site are submitted in various forms such
              as text, audio and/or video, and are reviewed by us before being
              posted. They appear on the Site verbatim as given by the users,
              except for the correction of grammar or typing errors. Some
              testimonials may have been shortened for the sake of brevity where
              the full testimonial contained extraneous information not relevant
              to the general public.
            </p>
            <p>
              The views and opinions contained in the testimonials belong solely
              to the individual user and do not reflect our views and opinions.
              We are not affiliated with users who provide testimonials, and
              users are not paid or otherwise compensated for their
              testimonials.
            </p>
            <p>
              The testimonials on the Site are not intended, nor should they be
              construed, as claims that our products and/or services can be used
              to diagnose, treat, mitigate, cure, prevent or otherwise be used
              for any disease or medical condition. No testimonials have been
              clinically proven or evaluated.
            </p>
          </div>
        </section>
        <!-- ! Section end -->
      </div>
      <!-- ! Section start -->
      <section
        id="lets_talk"
        class="section curved-section lets-talk-section cover-bg text-white"
        style="
          background-image: url(../../assets/images/contact-blockimage.jpg);
        "
      >
        <!--   <section class="section curved-section lets-talk-section cover-bg"> -->
        <div class="container section-container-padding">
          <div class="section-top-bar d-flex">
            <div class="section-top-bar-container">
              <h2 class="section-title title-white">Let's <span>talk</span></h2>
            </div>
          </div>
          <div class="contact-box">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              class="php-email-form h-100"
            >
              <div class="row">
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div class="validate"></div>
                </div>
              </div>
              <div class="form-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div class="validate"></div>
              </div>
              <div class="form-group mb-4">
                <textarea
                  class="form-control"
                  name="message"
                  rows="5"
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message"
                ></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-orange" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <!-- ! Section end -->
    </main>
    <!-- ! Main end -->
    <!-- <div class="push"></div> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
//Footer,
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/screen.css";

#lets_talk {
  background-image: url("../../assets/images/contact-blockimage.jpg") !important;
}
#banner {
  background-image: url("../../assets/images/inneer-banner.jpg") !important;
}
</style>
